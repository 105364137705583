import React from "react";
import { useSelector } from "react-redux";

import Sources from "components/dashboard/Sources";
import Exam from "components/dashboard/Exam";
import Invoice from "components/dashboard/subscription/Invoice";
import Profile from "components/general/Profile";
import Index from "components/dashboard/Index";
import Statistics from "components/general/Statistics";
import NoteContent from "./NoteContent";
import Feed from "./Feed";

const Main = () => {
  const isProfileVisible = useSelector((state) => state.home.profile);
  const isInvoiceVisible = useSelector((state) => state.home.invoice);
  const isPlaylistVisible = useSelector((state) => state.home.playlists);
  const isDashboardVisible = useSelector((state) => state.home.dashboard);
  const isStatisticsVisible = useSelector((state) => state.home.statistics);
  const isExamVisible = useSelector((state) => state.home.exams);
  const isNoteVisible = useSelector((state) => state.home.note);
  const isNotePageVisible = useSelector((state) => state.home.sources);
  const { user } = useSelector((state) => state.user);

  return (
    <div
      className="content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div className="container-xxl" id="kt_content_container">
        {isPlaylistVisible && <Sources />}
        {isNoteVisible && <Sources />}
        {isInvoiceVisible && <Invoice />}
        {isProfileVisible && <Profile />}
        {user && isDashboardVisible && <Index />}
        {isStatisticsVisible && <Statistics />}
        {isExamVisible && <Exam />}
        {isNotePageVisible && <NoteContent />}
      </div>
    </div>
  );
};

export default Main;
