import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

const SubsInfo = ({
  setSelectedPlan,
  setSelectedYear,
  setSelectedFaculty,
  selectedPlan,
  setPrice,
  selectedFaculty,
  setMonths,
  selectedAccountNumber,
  setSelectedAccountNumber,
}) => {
  const { plans } = useSelector((state) => state.subscription);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#000" : "#333", // Dark text for options
      backgroundColor: state.isFocused ? "#ddd" : "transparent", // Highlight on focus
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#fff", // Light background for the menu
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000", // Dark text for the selected value
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#fff", // Control background
      borderColor: state.isFocused ? "#aaa" : "#ccc",
      boxShadow: state.isFocused ? "0 0 0 2px rgba(0,0,0,0.1)" : null,
    }),
  };

  const handlePlanChange = (selectedOption) => {
    setSelectedPlan(selectedOption.plan);
    setSelectedYear(null);
  };

  // Handle faculty change
  const handleFacultyChange = (selectedOption) => {
    setSelectedFaculty(selectedOption);
  };

  const handleAccountNumbersChange = (selectedOption) => {
    setSelectedAccountNumber(selectedOption.value);
  };

  const yearOptions = [
    { value: "FIRST", label: "1ème année" },
    { value: "THIRD", label: "3ème année" },
    { value: "FOURTH", label: "4ème année" },
    { value: "FIFTH", label: "5ème année" },
    { value: "SIXTH", label: "6ème année" },
  ];

  const facultyOptions = [{ value: 31, label: "Oran" }];
  const accountNumbersOptions = [
    { value: 1, label: "1 compte" },
    { value: 5, label: "5 comptes" },
  ];
  // Generate plan options based on the selected faculty
  const filteredPlans = plans.filter((plan) => {
    if (selectedFaculty && selectedFaculty.label === "Oran") {
      return plan.title === "RESIDANAT";
    }
    return true;
  });

  const planOptions = plans.map((plan) => ({
    value: plan.id,
    label: plan.title,
    plan,
  }));

  return (
    <div className="d-flex flex-column mb-8 fv-row mt-3">
      <h2 className="mb-3">Demandez un abonement:</h2>
      <div className="d-flex flex-row mb-5">
        <div className="d-flex flex-column align-items-start flex-grow-1 mx-3">
          <label className="d-flex form-label fs-5 fw-semibold">
            Sélectionnez faculté :
          </label>
          <Select
            options={facultyOptions}
            onChange={handleFacultyChange}
            isSearchable={false}
            styles={customStyles}
            placeholder="Choisir une faculté"
            className="w-100"
          />
        </div>
        <div className="d-flex flex-column align-items-start flex-grow-1 mx-3">
          <label className="d-flex form-label fs-5 fw-semibold">
            Sélectionnez plan :
          </label>
          <Select
            options={planOptions}
            placeholder="Choisir un plan"
            onChange={handlePlanChange}
            className="w-100"
            isSearchable={false}
            isDisabled={!selectedFaculty}
            styles={customStyles}
          />
        </div>
        {selectedPlan && selectedPlan.title === "EXTERNAT" && (
          <div className="d-flex flex-column align-items-start flex-grow-1 mx-3">
            <label className="d-flex form-label fs-5 fw-semibold">
              Sélectionnez année :
            </label>
            <Select
              options={yearOptions}
              placeholder="Choisir une année"
              isSearchable={false}
              onChange={(option) => {
                setSelectedYear(option);
              }}
              className="w-100"
              styles={customStyles}
            />
          </div>
        )}{" "}
      </div>
      <div className="d-flex flex-row mb-5">
        <div className="d-flex flex-column align-items-start flex-grow-1 mx-3">
          <label className="d-flex form-label fs-5 fw-semibold">
            Nombre de comptes :
          </label>
          <Select
            options={accountNumbersOptions}
            onChange={handleAccountNumbersChange}
            isSearchable={false}
            placeholder="Selectionner nombre de comptes"
            className="w-50"
            styles={customStyles}
          />
        </div>
      </div>

      <div className="d-flex flex-column">
        {selectedAccountNumber === 1 && (
          <>
            {selectedPlan && selectedPlan.title === "EXTERNAT" && (
              <>
                <label className="d-flex form-label fs-5 fw-semibold">
                  Nombre de mois :{" "}
                  <span className="fw-bold"> ( 200 DZD par mois )</span>
                </label>

                <label className="btn btn-outline mx-10 mb-2 btn-outline-dark btn-active-light-primary d-flex flex-stack text-start p-3">
                  <div className="d-flex align-items-center me-2">
                    <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="plan"
                        value="6 mois"
                        onChange={() => {
                          setMonths(6);
                          setPrice(1100);
                        }}
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                        6 Mois
                      </h2>
                      <div class="fw-semibold opacity-50">
                        Profitez de 100 DA de réduction
                      </div>
                    </div>
                  </div>
                  <div className="ms-5">
                    <span className="mb-2">DZD</span>
                    <span className="fs-2x fw-bold">1100</span>
                  </div>
                </label>
                <label className="btn btn-outline mx-10 mb-2 btn-outline-dark btn-active-light-primary d-flex flex-stack text-start p-3">
                  <div className="d-flex align-items-center me-2">
                    <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="plan"
                        value="9 mois"
                        onChange={() => {
                          setMonths(9);
                          setPrice(1500);
                        }}
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                        9 Mois
                      </h2>
                      <div class="fw-semibold opacity-50">
                        Profitez de 300 DA de réduction
                      </div>
                    </div>
                  </div>
                  <div className="ms-5">
                    <span className="mb-2">DZD</span>
                    <span className="fs-2x fw-bold">1500</span>
                  </div>
                </label>
                <label className="btn btn-outline mx-10 mb-2 btn-outline-dark btn-active-light-primary d-flex flex-stack text-start p-3">
                  <div className="d-flex align-items-center me-2">
                    <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="plan"
                        value="12 mois"
                        onChange={() => {
                          setMonths(12);
                          setPrice(1800);
                        }}
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                        12 Mois
                      </h2>
                      <div class="fw-semibold opacity-50">
                        Profitez de 600 DA de réduction
                      </div>
                    </div>
                  </div>
                  <div className="ms-5">
                    <span className="mb-2">DZD</span>
                    <span className="fs-2x fw-bold">1800</span>
                  </div>
                </label>
              </>
            )}

            {selectedPlan && selectedPlan.title === "RESIDANAT" && (
              <>
                <label className="d-flex form-label fs-5 fw-semibold">
                  Nombre de mois :
                  <span className="fw-bold"> ( 500 DZD par mois )</span>
                </label>
                <label className="btn btn-outline mx-10 mb-2 btn-outline-dark btn-active-light-primary d-flex flex-stack text-start p-3">
                  <div className="d-flex align-items-center me-2">
                    <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="plan"
                        value="6 mois"
                        onChange={() => {
                          setMonths(6);
                          setPrice(2800);
                        }}
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                        6 Mois
                      </h2>
                      <div class="fw-semibold opacity-50">
                        Profitez de 200 DA de réduction
                      </div>
                    </div>
                  </div>
                  <div className="ms-5">
                    <span className="mb-2">DZD</span>
                    <span className="fs-2x fw-bold">2800</span>
                  </div>
                </label>
                <label className="btn btn-outline mx-10 mb-2 btn-outline-dark btn-active-light-primary d-flex flex-stack text-start p-3">
                  <div className="d-flex align-items-center me-2">
                    <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="plan"
                        value="9 mois"
                        onChange={() => {
                          setMonths(9);
                          setPrice(3900);
                        }}
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                        9 Mois
                      </h2>
                      <div class="fw-semibold opacity-50">
                        Profitez de 600 DA de réduction
                      </div>
                    </div>
                  </div>
                  <div className="ms-5">
                    <span className="mb-2">DZD</span>
                    <span className="fs-2x fw-bold">3900</span>
                  </div>
                </label>
                <label className="btn btn-outline mx-10 mb-2 btn-outline-dark btn-active-light-primary d-flex flex-stack text-start p-3">
                  <div className="d-flex align-items-center me-2">
                    <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="plan"
                        value="12 mois"
                        onChange={() => {
                          setMonths(12);
                          setPrice(5000);
                        }}
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                        12 Mois
                      </h2>
                      <div class="fw-semibold opacity-50">
                        Profitez de 1000 DA de réduction
                      </div>
                    </div>
                  </div>
                  <div className="ms-5">
                    <span className="mb-2">DZD</span>
                    <span className="fs-2x fw-bold">5000</span>
                  </div>
                </label>
              </>
            )}
          </>
        )}
        {selectedPlan && selectedPlan.title === "RESIDANAT" && (
          <>
            {selectedAccountNumber === 5 && (
              <>
                <label className="d-flex form-label fs-5 fw-semibold">
                  Nombre de mois :{" "}
                  <span className="fw-bold"> ( 5000 DZD par compte )</span>
                </label>
                <label className="btn btn-outline mx-10 mb-2 btn-outline-dark btn-active-light-primary d-flex flex-stack text-start p-3">
                  <div className="d-flex align-items-center me-2">
                    <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="plan"
                        value="12 mois"
                        onChange={() => {
                          setMonths(12);
                          setPrice(20000);
                        }}
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                        12 Mois
                      </h2>
                      <div class="fw-semibold opacity-50">
                        Profitez d'un compte gratuit
                      </div>
                    </div>
                  </div>
                  <div className="ms-5">
                    <span className="mb-2">DZD</span>
                    <span className="fs-2x fw-bold">20000</span>
                  </div>
                </label>
              </>
            )}
          </>
        )}
        {selectedPlan && selectedPlan.title === "EXTERNAT" && (
          <>
            {selectedAccountNumber === 5 && (
              <>
                <label className="d-flex form-label fs-5 fw-semibold">
                  Nombre de mois :
                </label>
                <label className="btn btn-outline mx-10 mb-2 btn-outline-dark btn-active-light-primary d-flex flex-stack text-start p-3">
                  <div className="d-flex align-items-center me-2">
                    <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="plan"
                        value="06 mois"
                        onChange={() => {
                          setMonths(9);
                          setPrice(6000);
                        }}
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                        09 Mois
                      </h2>
                      <div class="fw-semibold opacity-50">
                        Profitez d'un compte gratuit
                      </div>
                    </div>
                  </div>
                  <div className="ms-5">
                    <span className="mb-2">DZD</span>
                    <span className="fs-2x fw-bold">6000</span>
                  </div>
                </label>
                <label className="btn btn-outline mx-10 mb-2 btn-outline-dark btn-active-light-primary d-flex flex-stack text-start p-3">
                  <div className="d-flex align-items-center me-2">
                    <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="plan"
                        value="12 mois"
                        onChange={() => {
                          setMonths(12);
                          setPrice(7200);
                        }}
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                        12 Mois
                      </h2>
                      <div class="fw-semibold opacity-50">
                        Profitez d'un compte gratuit
                      </div>
                    </div>
                  </div>
                  <div className="ms-5">
                    <span className="mb-2">DZD</span>
                    <span className="fs-2x fw-bold">7200</span>
                  </div>
                </label>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SubsInfo;
