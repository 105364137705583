import {
  getSessionQuestions,
  resetRestart,
  setRestartWrong,
} from "features/medecine";
import React from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";

const Statistics = ({
  options,
  data,
  newSessionStats,
  setNewSessionStats,
  setFinished,
  setCurrentMyQuestionIndex,
  setVerified,
  setVisitedQuestions,
  session_id,
}) => {
  const dispatch = useDispatch();

  const { restart } = useSelector((state) => state.medecine);
  const { subscription } = useSelector((state) => state.subscription);
  const handleRedoSessionClick = () => {
    setFinished(false);
    setCurrentMyQuestionIndex(0);
    setVerified(false);
    setVisitedQuestions([]);
    setNewSessionStats({
      fauxCount: 0,
      justeCount: 0,
      partJusteCount: 0,
      partielNegatif: 0,
      partielPositif: 0,
      toutOuRien: 0,
      selectedPartCours: [],
      marksByPartCours: {},
      dureeTotale: {},
      dureeMoyenne: {},
    });
    dispatch(
      getSessionQuestions({
        session_id: session_id,
        restart_session: true,
      })
    );
    if (!restart) {
      dispatch(resetRestart());
    }
  };
  const handleRedoWrongSessionClick = () => {
    setFinished(false);
    setCurrentMyQuestionIndex(0);
    setVerified(false);
    setVisitedQuestions([]);
    setNewSessionStats({
      fauxCount: 0,
      justeCount: 0,
      partJusteCount: 0,
      partielNegatif: 0,
      partielPositif: 0,
      toutOuRien: 0,
      selectedPartCours: [],
      marksByPartCours: {},
      dureeTotale: {},
      dureeMoyenne: {},
    });
    dispatch(
      getSessionQuestions({
        session_id: session_id,
        restart_wrong: true,
      })
    );
    dispatch(setRestartWrong(true));
  };
  return (
    <div className="card card-xxl-stretch mb-5 mb-xxl-8">
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            Vous avez terminer cette session
          </span>
          <span className="text-muted mt-1 fw-semibold fs-7">
            Voici vos statistiques
          </span>
        </h3>
        <div className="card-toolbar">
          <ul className="nav gap-3">
            <li className="nav-item">
              <button
                className="nav-link btn btn-sm  btn-active btn-active-primary fw-bold px-4 me-1 active"
                onClick={handleRedoSessionClick}
              >
                Refaire cette session
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link btn btn-sm  btn-active btn-active-danger fw-bold px-4 me-1 active"
                onClick={handleRedoWrongSessionClick}
              >
                Refaire Questions Erronées (
                {newSessionStats.fauxCount + newSessionStats.partJusteCount})
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div className="card-body card-scroll  h-450px py-3">
        <div className="tab-content">
          <div
            className="tab-pane fade show active"
            id="kt_table_widget_5_tab_1"
          >
            <div className="d-flex flex-wrap flex-stack py-3 me-3">
              <div className="d-flex flex-column flex-grow-2">
                <div className="d-flex flex-wrap py-8">
                  <Chart
                    options={options}
                    series={data}
                    type="donut"
                    width="100%"
                  />
                </div>
              </div>
              <div className="d-flex flex-column flex-grow-2">
                <span className="text-muted mt-1 fw-semibold fs-5">
                  Nombre de reponses
                </span>

                <div className="d-flex flex-wrap py-8">
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <i className="ki-duotone ki-cross-square fs-2x text-danger me-2">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">
                          {newSessionStats.fauxCount}
                        </span>
                        <br />
                      </h3>
                    </div>
                    <div className="fw-semibold fs-6 text-gray-400">
                      Repondu faux
                    </div>
                  </div>
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <i className="ki-duotone ki-check fs-2x text-success me-2">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">
                          {newSessionStats.justeCount}
                        </span>
                        <br />
                      </h3>
                    </div>
                    <div className="fw-semibold fs-6 text-gray-400">
                      Repondu justes
                    </div>
                  </div>
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <i className="ki-duotone ki-arrow-up-refraction fs-2x text-warning me-2">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">
                          {newSessionStats.partJusteCount}
                        </span>
                        <br />
                      </h3>
                    </div>
                    <div className="fw-semibold fs-6 text-gray-400">
                      Part/ justes
                    </div>
                  </div>
                </div>
                {subscription &&
                  subscription.is_active &&
                  subscription.activation_code_fields.plan === "EXTERNAT" &&
                  subscription.activation_code_fields?.year === "FIRST" && (
                    <div className="col-xxl-6">
                      <div
                        className="card border-0 h-md-100"
                        data-bs-theme="light"
                        style={{
                          background:
                            "linear-gradient(112.14deg, #9A7BDB 0%, #8A2BE2 100%)",
                        }}
                      >
                        <div className="card-body">
                          <div className="row align-items-center h-100">
                            <div className="col-7 ps-xl-13">
                              <div className="text-white mb-6 pt-6">
                                <span className="fs-4 fw-semibold me-2 d-block lh-1 pb-2 opacity-75">
                                  Pour mieux comprendre les QCMs
                                </span>
                                <span className="fs-2qx fw-bold">
                                  Consulter les cours d’
                                  <span className="text-warning">
                                    IMS Academy
                                  </span>
                                </span>
                              </div>
                              <div className="d-flex flex-column flex-sm-row d-grid gap-2">
                                <a
                                  href="https://imsacademydz.super.site/"
                                  className="btn btn-warning text-dark flex-shrink-0 me-lg-2"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Consulter
                                </a>
                              </div>
                            </div>
                            <div className="col-5 pt-10">
                              <div
                                className="bgi-no-repeat bgi-size-contain bgi-position-x-end h-300px"
                                style={{
                                  backgroundImage:
                                    "url('assets/media/svg/illustrations/easy/ims.png')",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
