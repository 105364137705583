import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "containers/HomePage";
import DashboardPage from "containers/DashboardPage";
import LoginPage from "containers/LoginPage";
import VerifyEmailPage from "containers/VerifyEmailPage";
import RegisterPage from "containers/RegisterPage";
import ResetPassword from "containers/ResetPassword";
import ResetPasswordConfirm from "containers/ResetPasswordConfirm";
import Activate from "containers/Activate";
import QuizPage from "containers/QuizPage";

const App = () => {
  return (
    <Router>

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/verify-email" element={<VerifyEmailPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/password/reset/confirm/:uid/:token"
          element={<ResetPasswordConfirm />}
        />
        <Route path="/activate/:uid/:token/" element={<Activate />} />
        <Route path="/quiz" element={<QuizPage />} />
        <Route path="/exam" element={<QuizPage />} />
      </Routes>
    </Router>
  );
};

export default App;
