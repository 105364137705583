import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";

import {
  postAnswer,
  createPlaylist,
  getSessionQuestions,
  getPlaylistQuestions,
  resetRestart,
  setRestartWrong,
} from "features/medecine";

import * as questionUtils from "../utils/questionUtils";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Statistics from "components/quiz/Statistics";
import SignalModal from "components/quiz/SignalModal";
import PropositionCheckbox from "./PropositionCheckbox";
import renderSkeleton from "./renderSkelton";
import Comment from "./Comment";
import NoteContent from "components/dashboard/NoteContent";

const Question = () => {
  const dispatch = useDispatch();
  const {
    loadingQuestions,
    questions,
    session_id,
    playlist,
    questions_length,
    selectedCourseId,
    old_answer,
    restart,
    restart_wrong,
  } = useSelector((state) => state.medecine);
  const assistant = useSelector((state) => state.home.assistant);

  const course_id = selectedCourseId;

  const questionsLenRef = useRef(questions_length);

  useEffect(() => {
    if (restart) {
      // Update ref only if this is a new restart
      questionsLenRef.current = questions_length;
    }
    dispatch(resetRestart());
  }, [questions_length, restart]);

  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [oldAnswer, setOldAnswer] = useState("");

  useEffect(() => {
    setOldAnswer(oldAnswer);
  }, [old_answer]);

  const toggleCommentDrawer = () => {
    setIsCommentOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (questionsLenRef.current === undefined) {
      questionsLenRef.current = questions_length;
    }
  }, [questions_length]);
  const questions_len = questionsLenRef.current;
  const [newSessionStats, setNewSessionStats] = useState({
    fauxCount: 0,
    justeCount: 0,
    partJusteCount: 0,
    partielNegatif: 0,
    partielPositif: 0,
    toutOuRien: 0,
    selectedPartCours: [],
    marksByPartCours: {},
    dureeTotale: {},
    dureeMoyenne: {},
  });
  const [verified, setVerified] = useState(false);
  const [questionIndexClasses, setQuestionIndexClasses] = useState({});
  const [finished, setFinished] = useState(false);
  const [evaluation, setEvaluation] = useState(null);
  const [answeredQuestionsLength, setAnsweredQuestionsLength] = useState(0);

  const data = [
    newSessionStats.fauxCount,
    newSessionStats.justeCount,
    newSessionStats.partJusteCount,
  ];
  const labels = ["Faux", "justes", "Part/ justes"];

  const options = {
    series: data,
    labels: labels,
    colors: ["#F44336", "#4CAF50", "#FF9800"],
    chart: {
      type: "donut",
    },
    toolbar: {
      show: false,
    },
  };

  let old_data = [];
  let old_options = {};

  const [visitedQuestions, setVisitedQuestions] = useState([]);
  const questionTextRef = useRef(null);
  const [currentMyQuestionIndex, setCurrentMyQuestionIndex] = useState(0);

  let currentQuestion = questions[currentMyQuestionIndex];
  const inputRefs = useRef([]);

  const showSwalTerminerLink = () => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      text: "Voulez-vous vraiment terminer cette session ?",
      icon: "warning",
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Terminer",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton: "btn btn-danger mx-2",
        cancelButton: "btn btn-success",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Terminer();
      }
    });
  };

  const handleVerify = () => {
    const currentQuestion = questions[currentMyQuestionIndex];
    console.log("currentQuestion :", currentQuestion);
    if (currentQuestion.scenario) {
      // Clinical Case Logic
      handleClinicalCase(currentQuestion);
    } else {
      // QCM Logic
      handleQCM(currentQuestion);
    }
  };

  const handleQCM = (currentQuestion) => {
    const updatedVisitedQuestions = [...visitedQuestions];
    console.log("updatedVisitedQuestions :", updatedVisitedQuestions);

    updatedVisitedQuestions[currentMyQuestionIndex] = true;
    setVisitedQuestions(updatedVisitedQuestions);

    const selectedPropositionIds = [];
    const questionPropsIds = [];
    const updatedPropositions = currentQuestion.propositions.map(
      (proposition) => {
        const checkbox = document.getElementById(
          `kt_radio_option_${proposition.id}`
        );
        const isChecked = checkbox.checked;
        if (isChecked) {
          selectedPropositionIds.push(proposition.id);
        }
        if (proposition.is_correct) {
          questionPropsIds.push(proposition.id);
        }
        return { ...proposition, isChecked };
      }
    );
    console.log("updatedPropositions :", updatedPropositions);

    // questionUtils.updatePropositionLabels(updatedPropositions);
    const current_evaluation = questionUtils.updateAnswerCounts(
      questionPropsIds,
      currentQuestion,
      selectedPropositionIds,
      setNewSessionStats,
      setEvaluation,
      questionIndexClasses,
      setQuestionIndexClasses
    );

    setVerified(true);
    const proposition_ids = selectedPropositionIds;
    const question_id = currentQuestion?.id;

    dispatch(
      postAnswer({
        question_id,
        proposition_ids,
        evaluation: current_evaluation,
        session_id,
      })
    )
      .then((response) => {
        if (response.payload) {
          const { payload } = response;
          if (assistant) {
            questionUtils.displayEvaluationMessage(
              payload,
              current_evaluation,
              currentQuestion.propositions
            );
          }
        }
      })
      .catch((error) => {
        console.error("Error dispatching answer:", error);
      });
  };

  const handleClinicalCase = (clinicalCase) => {
    const updatedVisitedQuestions = [...visitedQuestions];
    updatedVisitedQuestions[currentMyQuestionIndex] = true;
    setVisitedQuestions(updatedVisitedQuestions);
    console.log("updatedVisitedQuestions :", updatedVisitedQuestions);
    clinicalCase.questions.forEach((question) => {
      const selectedPropositionIds = [];
      const questionPropsIds = [];
      const updatedPropositions = question.propositions.map((proposition) => {
        const checkbox = document.getElementById(
          `kt_radio_option_${proposition.id}`
        );
        const isChecked = checkbox?.checked;
        if (isChecked) {
          selectedPropositionIds.push(proposition.id);
        }
        if (proposition.is_correct) {
          questionPropsIds.push(proposition.id);
        }
        return { ...proposition, isChecked };
      });

      questionUtils.updatePropositionLabels(updatedPropositions);

      const current_evaluation = questionUtils.updateAnswerCounts(
        questionPropsIds,
        question,
        selectedPropositionIds,
        setNewSessionStats,
        setEvaluation,
        questionIndexClasses,
        setQuestionIndexClasses
      );

      const proposition_ids = selectedPropositionIds;
      const question_id = question.id;

      dispatch(
        postAnswer({
          question_id,
          proposition_ids,
          evaluation: current_evaluation,
          session_id,
        })
      )
        .then((response) => {
          if (response.payload) {
            const { payload } = response;
            if (assistant) {
              questionUtils.displayEvaluationMessage(
                payload,
                current_evaluation,
                question.propositions
              );
            }
          }
        })
        .catch((error) => {
          console.error("Error dispatching answer:", error);
        });
    });

    setVerified(true); // Mark clinical case as verified after processing all questions
  };

  const prevQuestion = () => {
    if (currentMyQuestionIndex > 0) {
      setCurrentMyQuestionIndex(currentMyQuestionIndex - 1);
      setVerified(true);
    }
  };

  const nextQuestion = () => {
    if (currentMyQuestionIndex < questions_len - 1) {
      const nextQuestionIndex = currentMyQuestionIndex + 1;

      // Fetch new questions if the next index is a multiple of 5 and hasn't been visited
      if (
        nextQuestionIndex % 5 === 0 &&
        !visitedQuestions[nextQuestionIndex] &&
        !restart_wrong
      ) {
        if (session_id) {
          dispatch(getSessionQuestions({ session_id: session_id }));
        } else {
          const page = Math.floor(nextQuestionIndex / 5) + 1;
          dispatch(getPlaylistQuestions({ course_id, page }));
        }
      }

      // Update verification status based on whether the next question has been visited
      setVerified(visitedQuestions[nextQuestionIndex] || false);

      // Move to the next question
      setCurrentMyQuestionIndex(nextQuestionIndex);
    }
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 0);
  };

  const Terminer = () => {
    setFinished(true);
    setVerified(false);
    dispatch(setRestartWrong(false));
    const answered_questions_length =
      newSessionStats.fauxCount +
      newSessionStats.justeCount +
      newSessionStats.partJusteCount;
    setAnsweredQuestionsLength(answered_questions_length);
  };

  const buttonReff = useRef(null);
  const [playlistMessage, setPlaylistMessage] = useState(null);
  const notify = () => toast.success(playlistMessage);

  const handleButtonClick = () => {
    const toastButton = buttonReff.current;
    if (toastButton) {
      if (playlist) {
        setPlaylistMessage("Question ajoutée aux playlists avec succès");
      } else {
        setPlaylistMessage("Question supprimée des playlists avec succès");
      }
      toastButton.click();
    }
  };
  const handleCreatePlaylist = () => {
    const question_id = currentQuestion.id;
    dispatch(createPlaylist({ question_id }));

    handleButtonClick();
  };

  const showSwalWithLink = () => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      text: "Voulez-vous vraiment supprimer cette question de la playlist ?",
      icon: "warning",
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Supprimer",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton: "btn btn-danger mx-2",
        cancelButton: "btn btn-success",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const question_id = currentQuestion.id;
        dispatch(createPlaylist({ question_id })).then(() => {
          handleButtonClick();
        });
      }
    });
  };

  const showQuestionInfo = () => {
    const MySwal = withReactContent(Swal);
    const sourcesBadges = currentQuestion?.sources
      ?.map(
        (source) =>
          `<span className="badge badge-warning fs-5 m-1">${source},</span>`
      )
      .join(" ");
    const yearBadges =
      currentQuestion?.annee_univ
        ?.map((year) => `<span className="badge badge-primary">${year}</span>`)
        .join(" ") || "Non spécifié";
    MySwal.fire({
      title: "Infos sur la question",
      html: `
        <div style="text-align: left;">
          <table style="width: 100%; border-collapse: collapse;">
            <tr>
              <th style="text-align: left; padding: 1px; border-bottom: 1px solid #ddd;">Module</th>
              <td style="padding: 8px; border-bottom: 1px solid #ddd;">${currentQuestion.module}</td>
          </tr>
          <tr>
              <th style="text-align: left; padding: 8px; border-bottom: 1px solid #ddd;">Cours</th>
              <td style="padding: 8px; border-bottom: 1px solid #ddd;">${currentQuestion?.course}</td>
          </tr>
          <tr>
              <th style="text-align: left; padding: 8px; border-bottom: 1px solid #ddd;">Sources</th>
              <td style="padding: 8px; border-bottom: 1px solid #ddd;">${sourcesBadges}</td>
          </tr>
          <tr>
              <th style="text-align: left; padding: 8px; border-bottom: 1px solid #ddd;">Années</th>
              <td style="padding: 8px; border-bottom: 1px solid #ddd;">${yearBadges}</td>
          </tr>
        </tbody>
      </table>
      `,
      icon: "info",
      buttonsStyling: true,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonColor: "#6c757d",
      cancelButtonText: "Ok",
      customClass: {
        cancelButton: "btn btn-success",
      },
    });
  };

  const showExplanation = () => {
    const MySwal = withReactContent(Swal);

    const isClinicalCase = currentQuestion?.scenario; // Check if the current item is a clinical case
    let explanationContent = "";

    if (isClinicalCase) {
      if (currentQuestion.questions?.length > 0) {
        explanationContent += "<br><strong>Question Explanations:</strong><br>";
        currentQuestion.questions.forEach((question, index) => {
          explanationContent += `<strong>Q${index + 1}:</strong> ${
            question.explanation?.replace("\\n", "<br>") ||
            "No explanation available"
          }<br>`;
        });
      }
    } else {
      // Handle QCM
      const explanation = currentQuestion?.explanation;
      if (typeof explanation !== "string") {
        console.error("Explanation is not a string");
        return;
      }
      explanationContent = explanation.replace("\\n", "<br>");
    }

    console.log("Formatted Explanation:", explanationContent);

    MySwal.fire({
      html: explanationContent,
      showCancelButton: false,
      showConfirmButton: true,
      width: "100%",
      heightAuto: true,
      padding: "0em", 
      customClass: {
        popup: "auto-size-popup",
      },
      scrollbarPadding: false,
    });
  };

  const renderTerminerButton = () => (
    <button
      className="btn btn-secondary btn-sm fw-semibold text-white me-3"
      onClick={showSwalTerminerLink}
    >
      Terminer
    </button>
  );

  // Function to render the "Précédent" button
  const renderPrevButton = (isEnabled) => {
    const isMobile = window.innerWidth <= 768;

    return (
      <button
        type="button"
        onClick={isEnabled ? prevQuestion : null}
        className={`btn btn-precedent ${
          isMobile ? "btn-icon" : ""
        } btn-sm hover-rotate-start text-white me-1 ${
          !isEnabled ? "disabled" : ""
        }`}
        disabled={!isEnabled}
      >
        <i className="ki-duotone ki-double-left-arrow text-white fs-2">
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
        {!isMobile && <span> Précédent</span>}
      </button>
    );
  };

  const renderNextButton = (isEnabled) => {
    const isMobile = window.innerWidth <= 768;

    return (
      <button
        type="button"
        onClick={isEnabled ? nextQuestion : null}
        className={`btn btn-danger ${
          isMobile ? "btn-icon" : ""
        } btn-sm hover-rotate-end me-1 ${!isEnabled ? "disabled" : ""}`}
        disabled={!isEnabled}
      >
        {!isMobile && <span> Suivant</span>}
        <i className="ki-duotone ki-double-right-arrow text-white fs-2">
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
      </button>
    );
  };

  // Function to render the "Verifier" button
  const renderVerifierButton = (isEnabled) => (
    <button
      className={`btn btn-info btn-sm me-1         
      `}
      onClick={isEnabled ? handleVerify : null}
    >
      Verifier
    </button>
  );

  const renderExplainButton = () => (
    <button className={`btn btn-dark btn-sm me-1 `} onClick={showExplanation}>
      Explication
    </button>
  );

  const renderButtons = () => {
    return (
      <div className="d-flex flex-row-fluid flex-end">
        {renderPrevButton(currentMyQuestionIndex >= 1)}
        {renderNextButton(
          currentMyQuestionIndex < questions_len - 1 &&
            visitedQuestions[currentMyQuestionIndex]
        )}
        {verified
          ? renderExplainButton()
          : renderVerifierButton(!visitedQuestions[currentMyQuestionIndex])}
      </div>
    );
  };

  const renderAnneeUniv = (question) => {
    return question?.annee_univ?.length > 0
      ? question.annee_univ
          .filter((annee) => annee !== "nan")
          .map((annee, index) => (
            <span key={index} className="badge badge-info mx-0">
              {annee}
            </span>
          ))
      : null;
  };
  const renderQuestion = (question, index) => (
    <div
      key={index}
      className="card card-flush border-gray-700 border rounded-0 card-stretch-75"
      hidden={index !== currentMyQuestionIndex}
    >
      <div className="card-header border-gray-700 p-3 pb-0 d-block ribbon ribbon-top">
        <div className="ribbon-label bg-primary">
          {`Question ${currentMyQuestionIndex + 1}/${questions_len}`}
        </div>
        <div className="card-title fs-4 p-3">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-center align-items-center question-container">
              <div
                ref={questionTextRef}
                dangerouslySetInnerHTML={{ __html: question?.question_text }}
              />
            </div>
            <div className="d-flex flex-row gap-2 mt-2">
              {question?.sources?.length > 0 && (
                <span className="badge badge-success">
                  {question.sources.join(", ")}
                </span>
              )}
              {renderAnneeUniv(question)}
            </div>
          </div>
        </div>
      </div>
      <div className="card-body bg-transparent">
        {question?.propositions?.map((proposition, index) => (
          <PropositionCheckbox
            key={proposition.id}
            proposition={proposition}
            index={index}
            inputRefs={inputRefs}
            currentMyQuestionIndex={currentMyQuestionIndex}
            visitedQuestion={visitedQuestions[currentMyQuestionIndex]}
          />
        ))}
      </div>
      <div className="card-footer d-flex flex-end">
        {renderTerminerButton()}
        {renderButtons()}
      </div>
    </div>
  );
  const renderClinicalQuestions = (question, index) => (
    <div
      key={index}
      className="card card-flush border-gray-700 border rounded-2 shadow-lg card-stretch-75"
    >
      <div className="card-header border-gray-700 p-3 pb-0 d-block ribbon ribbon-top">
        <div className="ribbon-label bg-primary">{`Question ${index + 1}`}</div>
        <div className="card-title fs-4 p-3">
          <div className="d-flex flex-column">
            <div className="d-flex flex-row">
              <div
                ref={questionTextRef}
                dangerouslySetInnerHTML={{ __html: question?.question_text }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card-body bg-transparent">
        {question?.propositions?.map((proposition, index) => (
          <PropositionCheckbox
            key={proposition.id}
            proposition={proposition}
            index={index}
            inputRefs={inputRefs}
            currentMyQuestionIndex={currentMyQuestionIndex}
            visitedQuestion={visitedQuestions[currentMyQuestionIndex]}
          />
        ))}
      </div>
    </div>
  );
  const renderCasClinique = (casClinique, cIndex) => (
    <div
      key={cIndex}
      className="card card-flush border-gray-700 border rounded-2 card-stretch-75"
      hidden={cIndex !== currentMyQuestionIndex}
    >
      <div className="card-header border-gray-700 p-3 pb-0 d-block ribbon ribbon-top">
        <div className="ribbon-label bg-primary">
          {`Question ${currentMyQuestionIndex + 1}/${questions_len}`}
        </div>
        <div className="card-title fs-4 p-3">
          <div className="d-flex flex-column">
            <div className="d-flex flex-row">
              <div
                ref={questionTextRef}
                dangerouslySetInnerHTML={{ __html: casClinique.scenario }}
              />
            </div>
            <div className="d-flex flex-row gap-2 mt-3">
              {casClinique?.questions[0].sources?.length > 0 && (
                <span className="badge badge-success">
                  {casClinique?.questions[0].sources.join(", ")}
                </span>
              )}
              {renderAnneeUniv(casClinique?.questions[0])}
            </div>
          </div>
        </div>
      </div>
      {/* Questions Section */}
      <div className="questions-section mt-4 mx-5">
        {casClinique.questions?.map((question, index) => (
          <div key={index} className="mb-4">
            {renderClinicalQuestions(question, index)}
          </div>
        ))}
      </div>
      <div className="card-footer d-flex flex-end">
        {renderTerminerButton()}
        {renderButtons()}
      </div>
    </div>
  );

  return (
    <>
      <button
        hidden={true}
        type="button"
        className="btn btn-primary"
        onClick={notify}
        ref={buttonReff}
      >
        Toggle Toast
      </button>
      <div>
        <Toaster position="top-right" reverseOrder={false} />
      </div>

      <SignalModal currentQuestion={currentQuestion} />

      <div className={finished ? "col-xl-12 mb-5" : "col-xl-8 mb-5"}>
        {!finished &&
          (loadingQuestions
            ? renderSkeleton()
            : questions?.map((question, index) =>
                question.scenario
                  ? renderCasClinique(question, index)
                  : renderQuestion(question, index)
              ))}

        {finished && (
          <Statistics
            old_options={old_options}
            old_data={old_data}
            options={options}
            data={data}
            newSessionStats={newSessionStats}
            setNewSessionStats={setNewSessionStats}
            setFinished={setFinished}
            setCurrentMyQuestionIndex={setCurrentMyQuestionIndex}
            setVerified={setVerified}
            setVisitedQuestions={setVisitedQuestions}
            session_id={session_id}
          />
        )}

        {!finished && (
          <div className="app-engage">
            <button
              className="app-engage-btn hover-success"
              onClick={showQuestionInfo}
            >
              <i className="ki-solid ki-information fs-1 text-primary pt-1 mb-2"></i>
            </button>
            {currentQuestion?.is_playlist ? (
              <button
                className="app-engage-btn hover-success"
                onClick={showSwalWithLink}
              >
                <i className="ki-solid ki-archive-tick fs-1 text-primary pt-1 mb-2"></i>
              </button>
            ) : (
              <button
                className="app-engage-btn hover-success"
                onClick={handleCreatePlaylist}
              >
                <i className="ki-outline ki-archive-tick fs-1 text-primary pt-1 mb-2"></i>
              </button>
            )}
            <button
              className="app-engage-btn hover-success"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_signal"
            >
              <i className="ki-outline ki-flag fs-1 text-primary pt-1 mb-2"></i>{" "}
            </button>

            <button
              className={`app-engage-btn btn-light hover-success ${
                !verified ? "opacity-50" : ""
              }`}
              disabled={!verified}
              onClick={showExplanation}
            >
              <i className="ki-solid ki-notepad-bookmark fs-1 text-primary pt-1 mb-2"></i>
            </button>
            <button
              className={`app-engage-btn btn-info hover-success ${
                !verified ? "opacity-50" : ""
              }`}
              disabled={!verified}
              onClick={toggleCommentDrawer}
            >
              <div>
                <i className="ki-duotone ki-message-notif fs-1 text-primary pt-1 mb-2">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                </i>
              </div>
            </button>
          </div>
        )}
      </div>
      {!finished && (
        <div className="col-xl-4 mb-5">
          <NoteContent course_id={course_id} />
          <Comment
            currentQuestion={currentQuestion}
            isCommentOpen={isCommentOpen}
            toggleCommentDrawer={toggleCommentDrawer}
          />
          {/* <div className="card card-flush pt-1 mb-5 mb-lg-10">
            <div className="card-header">
              <div className="card-title">
                <h2 className="fw-bold">Raccourci</h2>
              </div>
            </div>
            <div className="card-body pt-0">
              {questions.map((question, index) => (
                <button
                  key={index}
                  id={`question-index-${question.id}`}
                  className={`btn ${getButtonClassName(index)} ${
                    questionIndexClasses[question.id]
                  } me-1 mb-2`}
                  onClick={() => switchBetweenQuestions(index)}
                >
                  {String(index + 1).padStart(2, "0")}
                </button>
              ))}
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default Question;
